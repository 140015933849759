<template>
    <div class="danger">
            <el-form :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
            <div class="fl-jus" style="margin-bottom: 10px">
                <div class="flex">
                    <div class="label">填表单位</div>
                    <el-form-item>
                        <el-input style="width: 360px" placeholder="请输入填表单位" v-model="singleData.gkpfwh"></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <div class="label">什么编码ZD-1？</div>
                    <el-form-item>
                        <el-input style="width: 176px" placeholder="请输入什么编码ZD-1？" v-model="singleData.gkpfwh"></el-input>
                    </el-form-item>
                </div>
            </div>
            <el-collapse v-model="Collapse" >
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="title">A.行政识别数据</div>
                    </template>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">1</div>
                            <div class="label1">路线编号</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">2</div>
                            <div class="label1">路线编号</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">3</div>
                            <div class="label1">路线编号</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
<!--                        <el-form-item label="编制完成" label-width="168px" >-->
<!--                            <el-checkbox style="width: 178px" v-model="singleData.gkbz">已完成</el-checkbox>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="完成时间" label-width="168px">-->
<!--                            <el-date-picker-->
<!--                                    format="yyyy-MM-dd"-->
<!--                                    value-format="yyyy-MM-dd"-->
<!--                                    v-model="singleData.gkbzwcsj" type="date" placeholder="选择日期">-->
<!--                            </el-date-picker>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="编制单位" label-width="168px">-->
<!--                            <el-input placeholder="请输入编制单位" v-model="singleData.gkbzdw"></el-input>-->
<!--                        </el-form-item>-->
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">4</div>
                            <div class="label1">桥梁编号</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">5</div>
                            <div class="label1">桥梁名称</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">6</div>
                            <div class="label1">桥位桩号</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <!--                        <el-form-item label="编制完成" label-width="168px" >-->
                        <!--                            <el-checkbox style="width: 178px" v-model="singleData.gkbz">已完成</el-checkbox>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item label="完成时间" label-width="168px">-->
                        <!--                            <el-date-picker-->
                        <!--                                    format="yyyy-MM-dd"-->
                        <!--                                    value-format="yyyy-MM-dd"-->
                        <!--                                    v-model="singleData.gkbzwcsj" type="date" placeholder="选择日期">-->
                        <!--                            </el-date-picker>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item label="编制单位" label-width="168px">-->
                        <!--                            <el-input placeholder="请输入编制单位" v-model="singleData.gkbzdw"></el-input>-->
                        <!--                        </el-form-item>-->
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">7</div>
                            <div class="label1">功能类型</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">8</div>
                            <div class="label1">下穿通道名</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">9</div>
                            <div class="label1">下穿通道桩号</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <!--                        <el-form-item label="编制完成" label-width="168px" >-->
                        <!--                            <el-checkbox style="width: 178px" v-model="singleData.gkbz">已完成</el-checkbox>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item label="完成时间" label-width="168px">-->
                        <!--                            <el-date-picker-->
                        <!--                                    format="yyyy-MM-dd"-->
                        <!--                                    value-format="yyyy-MM-dd"-->
                        <!--                                    v-model="singleData.gkbzwcsj" type="date" placeholder="选择日期">-->
                        <!--                            </el-date-picker>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item label="编制单位" label-width="168px">-->
                        <!--                            <el-input placeholder="请输入编制单位" v-model="singleData.gkbzdw"></el-input>-->
                        <!--                        </el-form-item>-->
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">10</div>
                            <div class="label1">设计荷载</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">11</div>
                            <div class="label1">通行载重</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">12</div>
                            <div class="label1">弯斜坡度</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <!--                        <el-form-item label="编制完成" label-width="168px" >-->
                        <!--                            <el-checkbox style="width: 178px" v-model="singleData.gkbz">已完成</el-checkbox>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item label="完成时间" label-width="168px">-->
                        <!--                            <el-date-picker-->
                        <!--                                    format="yyyy-MM-dd"-->
                        <!--                                    value-format="yyyy-MM-dd"-->
                        <!--                                    v-model="singleData.gkbzwcsj" type="date" placeholder="选择日期">-->
                        <!--                            </el-date-picker>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item label="编制单位" label-width="168px">-->
                        <!--                            <el-input placeholder="请输入编制单位" v-model="singleData.gkbzdw"></el-input>-->
                        <!--                        </el-form-item>-->
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">13</div>
                            <div class="label1">桥面铺装</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">14</div>
                            <div class="label1">管养单位</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">15</div>
                            <div class="label1">建成年限</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                    </div>
<!--                    <el-row>-->
<!--                        <el-form-item label="附件" label-width="168px">-->
<!--                            <label  class="fright">-->
<!--                                <i aria-hidden="true" class="upload-demo cursor el-button el-button&#45;&#45;primary el-button&#45;&#45;small">上传</i>-->
<!--                                <input type="file" v-show="false"  name="file" ref="file" id="fileInput" @change="onSubmit($event,'gkbzwj')" />-->
<!--                            </label>-->
<!--                        </el-form-item>-->
<!--                        <div :span="3" class="file" v-for="(item,index) in singleData.gkbzwj" :key="index">-->
<!--                            <a target="view_window" :href="baseUrl+item.url+item.name">-->
<!--                                <i class="el-icon-paperclip" style="color: #409EFF"></i><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span><i style="color: #409EFF" class="el-icon-delete" @click.stop="deleted(index,'gkbzwj')"></i>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </el-row>-->

                </el-collapse-item>
                <el-collapse-item title="反馈 Feedback" name="2">
                    <template slot="title">
                        <div class="title">B.结构技术数据</div>
                    </template>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">16</div>
                            <div class="label1">桥长</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">17</div>
                            <div class="label1">桥面总宽</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">18</div>
                            <div class="label1">车行道宽</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">19</div>
                            <div class="label1">桥面标高</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">20</div>
                            <div class="label1">桥下净高</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">21</div>
                            <div class="label1">桥上净高</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">22</div>
                            <div class="label1">引道总宽</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">23</div>
                            <div class="label1">引道路面宽</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">24</div>
                            <div class="label1">引道线形</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus" style="align-items: flex-start">
                        <div class="rowWrap">
                            <div class="row">
                                <div class="sb">上部结构</div>
                                <div class="add" @click="addUp"><i class="el-icon-circle-plus"></i>添加明细</div>
                            </div>
                            <div style="margin-top: 6px" v-for="(item,index) in singleData.sbjg" :key="index">
                                <div class="fl-jus">
                                    <div class="flex">
                                        <div class="sort">25</div>
                                        <div class="label1" style="width: 87px">孔号</div>
                                    </div>
                                    <div class="flex">
                                        <div class="sort">26</div>
                                        <div class="label1" style="width: 87px">形式</div>
                                    </div>
                                    <div class="flex">
                                        <div class="sort">27</div>
                                        <div class="label1" style="width: 87px">跨径</div>
                                    </div>
                                    <div class="flex">
                                        <div class="sort">28</div>
                                        <div class="label1" style="width: 87px">材料</div>
                                    </div>
                                </div>
                                <div class="fl-jus">
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入孔号" v-model="item.kh"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入形式" v-model="item.xs"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入跨径" v-model="item.kj"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入材料" v-model="item.cl"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="rowWrap">
                            <div class="row">
                                <div class="sb">下部结构</div>
                                <div class="add"><i class="el-icon-circle-plus"></i>添加明细</div>
                            </div>
                            <div style="margin-top: 6px">
                                <div class="fl-jus">
                                    <div class="flex">
                                        <div class="sort">29</div>
                                        <div class="label1" style="width: 87px">墩台</div>
                                    </div>
                                    <div class="flex">
                                        <div class="sort">30</div>
                                        <div class="label1" style="width: 87px">形式</div>
                                    </div>
                                    <div class="flex">
                                        <div class="sort">31</div>
                                        <div class="label1" style="width: 87px">材料</div>
                                    </div>
                                    <div class="flex">
                                        <div class="sort">32</div>
                                        <div class="label1" style="width: 87px">基础形式</div>
                                    </div>
                                </div>
                                <div class="fl-jus">
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入孔号" v-model="singleData.kh"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入形式" v-model="singleData.kh"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入跨径" v-model="singleData.kh"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input style="width: 127px" placeholder="请输入材料" v-model="singleData.kh"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">33</div>
                            <div class="label1">伸缩缝类型</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">34</div>
                            <div class="label1">支座形式</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">35</div>
                            <div class="label1" style="line-height: unset">地震动峰值 <br>加速度系数</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">36</div>
                            <div class="label1">桥台护坡</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">37</div>
                            <div class="label1">护墩体</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">38</div>
                            <div class="label1">调治构造物</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">39</div>
                            <div class="label1">常水位</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入常水位" v-model="singleData.csw"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">40</div>
                            <div class="label1">设计水位</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入设计水位" v-model="singleData.sjsw"></el-input>

                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">41</div>
                            <div class="label1">历史洪水位</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入历史洪水位" v-model="singleData.lshsw"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item name="3">
                    <template slot="title">
                        <div class="title">C.档案资料（全、不全或无）</div>
                    </template>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">42</div>
                            <div class="label1">设计图纸</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">43</div>
                            <div class="label1">设计文件</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">44</div>
                            <div class="label1">施工文件</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">45</div>
                            <div class="label1">竣工图纸</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">46</div>
                            <div class="label1">验收文件</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">47</div>
                            <div class="label1">行政文件</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">48</div>
                            <div class="label1">定期检查报告</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">49</div>
                            <div class="label1">特殊检查报告</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">50</div>
                            <div class="label1">历次维修资料</div>
                            <el-form-item>
                                <el-select style="width: 176px" placeholder="请选择项目类别" v-model="detail.xmlb">
                                    <el-option label="审批" value="审批"></el-option>
                                    <el-option label="核准" value="核准"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">51</div>
                            <div class="label1">档案号</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">52</div>
                            <div class="label1">存档案</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">53</div>
                            <div class="label1">建档年/月</div>
                            <el-form-item>
                                <el-date-picker
                                        style="width: 176px"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        v-model="singleData.cbsjbzwcsj" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse  v-model="Collapse" >
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="title">D.最近技术状况评定</div>
                    </template>
                   <div>
                       <div class="zk">
                           <span class="sb">状况评定明细1</span>
                       </div>
                       <div class="fl-jus">
                           <div class="flex">
                               <div class="sort">54</div>
                               <div class="label1">检查年月</div>
                               <el-form-item>
                                   <el-date-picker
                                           style="width: 176px"
                                           format="yyyy-MM-dd"
                                           value-format="yyyy-MM-dd"
                                           v-model="singleData.cbsjbzwcsj" type="date" placeholder="选择日期">
                                   </el-date-picker>
                               </el-form-item>
                           </div>
                           <div class="flex">
                               <div class="sort">55</div>
                               <div class="label1">定期或特殊检查</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                           <div class="flex">
                               <div class="sort">56</div>
                               <div class="label1">全桥评定等级</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                       </div>
                       <div class="fl-jus">
                           <div class="flex">
                               <div class="sort">57</div>
                               <div class="label1">桥台与基础</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请输入桥台与基础" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                           <div class="flex">
                               <div class="sort">58</div>
                               <div class="label1">桥墩与基础</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                           <div class="flex">
                               <div class="sort">59</div>
                               <div class="label1">地基冲刷</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                       </div>
                       <div class="fl-jus">
                           <div class="flex">
                               <div class="sort">60</div>
                               <div class="label1">上部结构</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                           <div class="flex">
                               <div class="sort">61</div>
                               <div class="label1">支座</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                           <div class="flex">
                               <div class="sort">62</div>
                               <div class="label1">经常保养小修</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                       </div>
                       <div class="fl-jus">
                           <div class="flex">
                               <div class="sort">63</div>
                               <div class="label1">处治对策</div>
                               <el-form-item>
                                   <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                               </el-form-item>
                           </div>
                           <div class="flex">
                               <div class="sort">64</div>
                               <div class="label1">下次检查年份</div>
                               <el-form-item>
                                   <el-date-picker
                                           style="width: 176px"
                                           format="yyyy-MM-dd"
                                           value-format="yyyy-MM-dd"
                                           v-model="singleData.cbsjbzwcsj" type="date" placeholder="选择日期">
                                   </el-date-picker>
                               </el-form-item>
                           </div>
                           <div class="zw"></div>
                       </div>
                   </div>
                    <div class="addD"><i class="el-icon-circle-plus"></i>添加明细</div>
                </el-collapse-item>
                <el-collapse-item name="2">
                    <template slot="title">
                        <div class="title">E.修建工程记录</div>
                    </template>
                    <div>
                        <div class="zk">
                            <span class="sb">记录1</span>
                        </div>
                        <div class="fl-jus">
                            <div class="flex">
                                <div class="sort">65</div>
                                <div class="label1">施工日期(开工)</div>
                                <el-form-item>
                                    <el-date-picker
                                            style="width: 176px"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            v-model="singleData.cbsjbzwcsj" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">65</div>
                                <div class="label1">施工日期(竣工)</div>
                                <el-form-item>
                                    <el-date-picker
                                            style="width: 176px"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            v-model="singleData.cbsjbzwcsj" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">66</div>
                                <div class="label1">修建类别</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fl-jus">
                            <div class="flex">
                                <div class="sort">67</div>
                                <div class="label1">修建原因</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请输入桥台与基础" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">68</div>
                                <div class="label1">工程范围</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">69</div>
                                <div class="label1">工程费用</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fl-jus">
                            <div class="flex">
                                <div class="sort">70</div>
                                <div class="label1">经费来源</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">71</div>
                                <div class="label1">质量评定</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">72</div>
                                <div class="label1">建设单位</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请输入路线编号" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fl-jus">
                            <div class="flex">
                                <div class="sort">73</div>
                                <div class="label1">设计单位</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">74</div>
                                <div class="label1">施工单位</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <div class="sort">75</div>
                                <div class="label1">监理单位</div>
                                <el-form-item>
                                    <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="addD"><i class="el-icon-circle-plus"></i>添加明细</div>
                </el-collapse-item>
                <el-collapse-item name="3">
                    <template slot="title">
                        <div class="title">F.桥梁照片</div>
                    </template>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">76</div>
                            <div class="label1">备注</div>
                            <el-form-item>
                                <el-input style="width: 915px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="fl-col">
                            <div class="flex">
                                <div class="sort">77</div>
                                <div class="label1 fl-jus" style="width: 496px">
                                    <div style="margin-left: 42px">立面照</div>
                                    <el-form-item>
                                        <label  class="fright">
                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                            <input style="width: 72px" type="file" v-show="false"  name="file" ref="file" id="fileInput" @change="onSubmit($event,'xygtkjghwj')" />
                                        </label>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="photo">
                                <div>立面照预览区</div>
                            </div>
                        </div>
                        <div class="fl-col">
                            <div class="flex">
                                <div class="sort">78</div>
                                <div class="label1 fl-jus" style="width: 496px">
                                    <div style="margin-left: 42px">桥面正面照</div>
                                    <el-form-item>
                                        <label  class="fright">
                                            <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                            <input style="width: 72px" type="file" v-show="false"  name="file" ref="file" id="fileInput" @change="onSubmit($event,'xygtkjghwj')" />
                                        </label>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="photo">
                                <div>桥面正面照预览区</div>
                            </div>
                        </div>
                    </div>
                    <div class="fl-jus">
                        <div class="flex">
                            <div class="sort">79</div>
                            <div class="label1">主管负责人</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">80</div>
                            <div class="label1">填卡人</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="sort">81</div>
                            <div class="label1">填卡日期</div>
                            <el-form-item>
                                <el-date-picker
                                        style="width: 176px"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        v-model="singleData.cbsjbzwcsj" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </div>

                    </div>
                    <el-divider></el-divider>
                    <div class="fl-jus" style="padding-bottom: 60px">
                        <div class="flex">
                            <div class="label1" style="width: 168px">填表人</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="label1" style="width: 168px">单位负责人</div>
                            <el-form-item>
                                <el-input style="width: 176px" placeholder="请选择" v-model="singleData.xlbh"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <div class="label1" style="width: 168px">填表时间</div>
                            <el-form-item>
                                <el-date-picker
                                        style="width: 176px"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        v-model="singleData.cbsjbzwcsj" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            </el-form>
<div class="footer">

    <el-button class="save" @click="save">保存</el-button>
    <el-button class="cancel">取消</el-button>
</div>
    </div>
</template>

<script>
    export default {
        name: "Danger",
        props:{
            open: { type: Boolean },
        },
        data(){
            return{
                uploadLoading: false,
                detail:{},
                qqDrawer: false,
                Collapse:['1','2','3','4','5','6'],
                singleData:{
                    sbjg:[{}],
                    xbjg:[]
                }
            }
        },
        watch:{
            open(val){
                console.log(val)
                this.qqDrawer = val
            }
        },
        methods:{
            save(){
               console.log(this.singleData)
            },
            addUp(){
                this.singleData.sbjg.push({})

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                console.log(file)
                let form = new FormData();
                let arrs = [];
                console.log(file);
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early_node/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail.FJ.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
        }
    }
</script>

<style scoped lang="less">
    .danger{

.flex{
    display: flex;
    align-items: center;
}
.fl-jus{
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.label{
    margin-right: 8px;
    width: 168px;
    height: 42px;
    background: #FAFAFA;
    line-height: 42px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
/deep/.el-step__title.is-process{
    color: #409EFF !important;
    border-color: #409EFF !important;
}
.title{
    padding: 0 16px;
    min-width: 98px;
    height: 32px;
    background: #409EFF;
    opacity: 1;
    line-height: 32px;
    text-align: center;
    color: #fff;
}
    .sort{
        width: 42px;
        height: 42px;
        line-height: 42px;
        background: #DCDFE6;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        opacity: 1;
        text-align: center;
    }
.label1{
    margin-right: 8px;
    width: 126px;
    height: 42px;
    background: #FAFAFA;
    line-height: 42px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
/deep/.el-collapse-item__header{
    padding: 0;
}
/deep/.el-collapse-item__content{
    padding: 10px 0 20px 0 !important;
}
    .rowWrap{
        width: 538px;
        .row{
            margin-top: 12px;
            width: 536px;
            border-bottom: 1px solid #C0C4CC;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .sb{
                text-align: center;
                width: 107px;
                height: 28px;
                line-height: 28px;
                background: #B3D8FF;
                opacity: 1;
                border-radius: 0px 14px 0px 0px;
                font-size: 16px;
                font-weight: 600;
            }
            .add{
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #67C23A;
                i{
                    font-size: 16px;
                    margin-right: 8px;
                }
            }
        }

    }
        .zk{
            margin-bottom: 10px;
            border-bottom: 1px solid #C0C4CC;
            .sb{
                text-align: center;
                padding: 5px 18px;
                height: 28px;
                line-height: 28px;
                background: #B3D8FF;
                opacity: 1;
                border-radius: 0px 14px 0px 0px;
                font-size: 16px;
                font-weight: 600;
            }
        }
        .footer{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 1128px;
            height: 46px;
            background: #F8F8F8;
            display: flex;
            align-items: center;
            justify-content: center;
            .save{
                cursor: pointer;
                width: 74px;
                background: #409EFF;
                opacity: 1;
                border-radius: 4px;
                color: #fff;
                text-align: center;
                margin-right: 47px;
            }
            .cancel{
                text-align: center;
                width: 74px;
                background: #FFFFFF;
                border: 1px solid #DCDFE6;
                border-radius: 4px;
                color: #666666;
            }
        }
    }
.zw{
    width: 362px;
}
    .addD{
        margin-top: 11px;
        width: 124px;
        height: 32px;
        background: #67C23A;
        opacity: 1;
        border-radius: 4px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        i{
            font-size: 16px;
            margin-right: 9px;
        }
    }
    .fl-col{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .photo{
            margin: 6px 0px;
            width: 520px;
            height: 251px;
            background: #FFFFFF;
            border: 1px dotted #666666;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #C0C4CC;
            img{
                width: 520px;
                height: 251px;
                border-radius: 10px;
            }
        }
    }
</style>
