<template>
    <div class="bridge">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划">
                                        <el-cascader style="width: 150px" size="medium" v-model="searchList.region.region" :options="searchList.region.regions" :props="{ expandTrigger: 'hover' }"></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="桥梁编码">
                                        <el-input style="width: 150px" size="medium" v-model="searchData[0].Value" placeholder="桥梁编码"></el-input>
                                    </el-form-item>

                                    <el-form-item label="桥梁类型">
                                        <el-cascader size="medium" style="width: 150px" v-model="searchList.GLLX.checked" :options="searchList.GLLX.data" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="计划实施年">
                                        <el-cascader size="medium" style="width: 150px" v-model="searchList.JHSSNX.checked" :options="searchList.JHSSNX.data" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="按跨境分">
                                        <el-cascader size="medium" style="width: 150px" v-model="searchList.AKJF.checked" :options="searchList.AKJF.data" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="评定等级">
                                        <el-cascader size="medium" style="width: 150px" v-model="searchList.PDDJ.checked" :options="searchList.PDDJ.data" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="设计荷载">
                                        <el-cascader size="medium" style="width: 150px" v-model="searchList.SJHZ.checked" :options="searchList.SJHZ.data" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 150px" v-model="searchList.JSXZ.checked" :options="searchList.JSXZ.data" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                </span>
                                <h3 class="tit">危桥列表</h3>
                            </div>
                            <div class="box-content">
                                <el-table @sort-change="changeSort" @row-dblclick="openDetails" ref="table" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                                    <el-table-column fixed prop="" label="操作" :width="130">
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes iconsyes1" @click="showMaps(scope.row)"><i class="el-icon-location icons"></i>定位</span>

                                            <span class="newicon iconsyes" @click="openDetails(scope.row)"><i class="el-icon-info icons"></i>详情</span>

                                            <!-- <i class="el-icon-location icons iconsyes" @click="showMaps(scope.row)"></i>
                                            <i class="el-icon-postcard icons iconsyes" @click="openDetails(scope.row)"></i>
                                            <i class="el-icon-thumb icons iconsyes" style="margin-right: 0;color:#f00" @click="openZp(scope.row)"></i> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed prop="LXBM" label="路线编码" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column fixed prop="LXMC" label="路线名称" width="130" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QLBM" label="桥梁编码" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QLMC" label="桥梁名称" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="HZQHMC" label="行政区划名称" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="ZXZH" label="中心桩号" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QC" label="桥长" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="KJZC" label="跨径总长" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="DKZDKJ" label="单孔最大跨径" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="KJZH" label="跨径组合" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QMJK" label="桥面净宽" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="KJFL" label="跨径分类" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="NXFL" label="年限分类" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QMPZLX" label="桥面铺装类型" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QTLX" label="桥台类型" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SBJGXS" label="上部结构形式" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SBJGCL" label="上部结构材料" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QDLX" label="桥墩类型" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SJHZ" label="设计荷载" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="KZDJ" label="抗震等级" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="FHBZ" label="防洪标准" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SFXZ" label="收费性质" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="PDDJ" label="评定等级" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="PDSJ" label="评定时间" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="PDDW" label="评定单位" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="BHWZ" label="病害位置" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="BHMC" label="病害名称" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JTGZCS" label="交通管制措施" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JCSJ" label="建成时间" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="GJSJ" label="改建时间" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="DSJDW" label="地市级单位" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="QXJDW" label="区县级单位" width="" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SFW2020NPTGXWQ" label="是否为2020年普通干线危桥" width="" ></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <el-drawer :title="singleData.LXMC" :append-to-body="true" :visible.sync="qqDrawer" size="1128px">
            <Danger></Danger>
        </el-drawer>
        <el-dialog :title="dialogTableVisibleData.XMMC" :visible.sync="dialogTableVisibleMap" class="mapdialog mapdialog1">
            <Map v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" />
        </el-dialog>
    </div>
</template>
<script>
import Map from "../Map/Index.vue";
import Danger from "../../components/Query/Road/Danger";
import { exportData } from "../../js/util/export.js"; //导出表格
export default {
    name: "Road_Bridge",
    components: { Danger, Map },
    data() {
        return {
            assign: false,
            activeProcess: 0,
            qqDrawer: false,
            singleData: {},
            searchData: [
                { Name: "QLBM", Value: "", DisplayType: "like" },
                { Name: "EJDW", Value: "", DisplayType: "Equal" },
                { Name: "SJDW", Value: "", DisplayType: "Equal" },
                { Name: "JHSSNX", Value: "", DisplayType: "checkbox" },
                { Name: "AKJF", Value: "", DisplayType: "checkbox" },
                { Name: "PDDJ", Value: "", DisplayType: "checkbox" },
                { Name: "SJHZ", Value: "", DisplayType: "checkbox" },
                { Name: "JSXZ", Value: "", DisplayType: "checkbox" },
                { Name: "GLLX", Value: "", DisplayType: "Equal" },
            ],
            searchList: {
                region: {
                    regions: [
                        {
                            value: "",
                            label: "黑龙江省",
                        },
                    ],
                    region: [""],
                },
                JHSSNX: {
                    name: "计划实施年",
                    data: [
                        {
                            value: "2021年",
                            label: "2021年",
                            disabled: false,
                        },
                        {
                            value: "2022年",
                            label: "2022年",
                            disabled: false,
                        },
                        {
                            value: "2023年",
                            label: "2023年",
                            disabled: false,
                        },
                        {
                            value: "2024年",
                            label: "2024年",
                            disabled: false,
                        },
                        {
                            value: "2025年",
                            label: "2025年",
                            disabled: false,
                        },
                    ],
                    indeterminate: true,
                    checkAll: false,
                    checked: [],
                },
                AKJF: {
                    name: "按跨境分",
                    data: [
                        {
                            value: "特大桥",
                            label: "特大桥",
                            disabled: false,
                        },
                        {
                            value: "大桥",
                            label: "大桥",
                            disabled: false,
                        },
                        {
                            value: "中桥",
                            label: "中桥",
                            disabled: false,
                        },
                        {
                            value: "小桥",
                            label: "小桥",
                            disabled: false,
                        },
                    ],
                    indeterminate: true,
                    checkAll: false,
                    checked: [],
                },
                PDDJ: {
                    name: "评定等级",
                    data: [
                        {
                            value: "一类",
                            label: "一类",
                            disabled: false,
                        },
                        {
                            value: "二类",
                            label: "二类",
                            disabled: false,
                        },
                        {
                            value: "三类",
                            label: "三类",
                            disabled: false,
                        },
                        {
                            value: "四类",
                            label: "四类",
                            disabled: false,
                        },
                        {
                            value: "五类",
                            label: "五类",
                            disabled: false,
                        },
                    ],
                    indeterminate: true,
                    checkAll: false,
                    checked: [],
                },
                SJHZ: {
                    name: "设计荷载",
                    data: [
                        {
                            value: "低于汽车－10级",
                            label: "低于汽车－10级",
                            disabled: false,
                        },
                        {
                            value: "公路－II级",
                            label: "公路－II级",
                            disabled: false,
                        },
                        {
                            value: "公路－I级",
                            label: "公路－I级",
                            disabled: false,
                        },
                        {
                            value: "汽车－10级",
                            label: "汽车－10级",
                            disabled: false,
                        },
                        {
                            value: "汽车－13级",
                            label: "汽车－13级",
                            disabled: false,
                        },
                        {
                            value: "汽车－15级",
                            label: "汽车－15级",
                            disabled: false,
                        },
                        {
                            value: "汽车－20级",
                            label: "汽车－20级",
                            disabled: false,
                        },
                        {
                            value: "汽车－超20级",
                            label: "汽车－超20级",
                            disabled: false,
                        },
                    ],
                    indeterminate: true,
                    checkAll: false,
                    checked: [],
                },
                JSXZ: {
                    name: "建设性质",
                    data: [
                        {
                            value: "新建",
                            label: "新建",
                            disabled: false,
                        },
                        {
                            value: "拆除重建",
                            label: "拆除重建",
                            disabled: false,
                        },
                        {
                            value: "维修加固",
                            label: "维修加固",
                            disabled: false,
                        },
                    ],
                    indeterminate: true,
                    checkAll: false,
                    checked: [],
                },
                GLLX: {
                    name: "桥梁类型",
                    data: [
                        {
                            value: "普通国道",
                            label: "普通国道",
                            disabled: false,
                        },
                        {
                            value: "普通省道",
                            label: "普通省道",
                            disabled: false,
                        },
                    ],
                    indeterminate: true,
                    checkAll: false,
                    checked: [],
                },
            },
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索
            region: [""], //已选中行政区划
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            tableData: [], //请求列表
            total: 0, //请求记录数
            dialogTableVisibleData: {},
            dialogTableVisibleMap: false,
            sortData: {
                field: "",
                sort: "asc",
            },
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 145;
        }, 100);
        this.getRegion();
        this.search();
    },
    methods: {
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
            } else {
                this.sortData = {
                    field: "",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        postData() {
            if (this.searchList.region.region.length > 0) {
                this.searchData[1].Value = this.searchList.region.region[0];
                if (this.searchList.region.region.length > 1) {
                    this.searchData[2].Value = this.searchList.region.region[1];
                }
            } else {
                this.searchData[1].Value = "";
                this.searchData[2].Value = "";
            }
            var search = ["JHSSNX", "AKJF", "PDDJ", "SJHZ", "JSXZ", "GLLX"];
            search.forEach((item) => {
                var val = this.searchList[item].checked.join(",");
                var find = this.searchData.find((r) => r.Name == item);
                find.Value = val;
            });
            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                wheres: JSON.stringify(this.searchData),
            };

            return postData;
        },
        exportData() {
            // this.$msg('123')
            this.$message.warning("功能未开放");
            // exportData("Plan_road", this.postData());
        },
        //卡片详情
        openDetails(row) {
            this.singleData = row;
            this.qqDrawer = true;
        },
        //加载地图
        showMaps(row) {
            return false;
            // this.dialogTableVisibleData = row;
            // this.dialogTableVisibleMap = true;
        },
        //指派
        openZp(row) {
            console.log(row);
        },
        getRegion() {
            var postData = { SSDS: "", SSX: "" };
            this.http
                .post("/api/Base_area/getAdminDivTree", postData)
                .then((res) => {
                    // console.log(res,456);
                    res.map((r) => {
                        r.value = r.label;
                        r.children.map((rr) => {
                            rr.value = rr.label;
                        });
                    });
                    this.searchList.region.regions = res;
                });
        },
        //查询
        search() {
            this.http
                .post(
                    "/api/Plan_danger_bridge/GetData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                    }
                });
        },
        clearCondition() {
            this.searchData.map((r) => (r.Value = ""));
            this.searchList.region.region = [];
            this.searchList.JHSSNX.checked = [];
            this.searchList.GLLX.checked = [];
            this.searchList.AKJF.checked = [];
            this.searchList.PDDJ.checked = [];
            this.searchList.SJHZ.checked = [];
            this.searchList.JSXZ.checked = [];
        },
        //全选操作
        handleCheckAll(e, value) {
            this.searchList[value].checked = e
                ? this.searchList[value].data
                : [];
            this.searchList[value].indeterminate = false;
        },
        //复选操作
        handleChecked(e, value) {
            let count = e.length;
            this.searchList[value].checkAll =
                count === this.searchList[value].data.length;
            this.searchList[value].indeterminate =
                count > 0 && count < this.searchList[value].data.length;
        },
        //分页数量
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        //点击页码
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>

<style lang="less" scoped>
.hidden {
    display: none;
}
.dl-item {
    dt {
        min-width: 84px;
    }
    dd {
        width: calc(100% - 170px);
    }
}
/deep/.el-collapse-item__header {
    height: 32px;
    padding: 0 15px;
    background: #f2f8fe !important;
}
/deep/.el-drawer__body {
    padding: 12px 15px 0px;
    /*padding-bottom: 60px;*/
}
</style>
